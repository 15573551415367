import {
  AutoIncrementData,
  DateData,
  DateTimeData,
  DimensionsData,
  DropdownData,
  FileData,
  GeoCoords,
  ItemValueDataModel,
  NumberData,
  OptionData,
  ProcedureData,
  ReminderData,
  SpecialLocale,
  UrlData,
} from '../../../../modules/crud/items/item-value/classes/item-value.model';
import { select, Store } from '@ngrx/store';
import {
  CollectionItemsState,
  getItemById,
} from '../../../../modules/crud/collections/collection-item/store/reducers/collection-item.reducer';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as CollectionItemActions from '../../../../modules/crud/collections/collection-item/store/actions/collection-item.actions';
import {LocaleUtilService} from "@dash/randy/shared/classes/locale-util.service";

export abstract class BaseDataTypeClass {
  abstract validate(data: ItemValueDataModel, locale?: string): boolean;

  abstract generateDefaultDataObject(locale?: string): ItemValueDataModel;

  abstract getDataAsString(data: ItemValueDataModel, locale?: string): Promise<string>;
}

export class UrlDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: SpecialLocale[], locale?: string): Promise<string> {
    if (!locale) {
      return (data[0].data as UrlData).name;
    }
    let localeData = data.find(row => row.locale === locale).data as UrlData;
    return localeData.name;
  }

  validate(data: SpecialLocale[], locale: string = null): boolean {
    if (!locale) {
      return data.length > 0;
    }
    let urlData = data.find(row => row.locale === locale).data as UrlData;
    return urlData.name.length > 0 && urlData.url.length > 0;
  }

  generateDefaultDataObject(locale: string): SpecialLocale[] {
    return [
      {
        locale: locale,
        data: { download: false, newTab: false, name: '', url: '' } as UrlData,
      },
    ] as SpecialLocale[];
  }
}

export class TextDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: SpecialLocale[], locale?: string): Promise<string> {
    if (!locale) {
      return data[0].data as string;
    }
    let localeData = data.find(row => row.locale === locale);
    if (localeData) {
      return localeData.data as string;
    }
    return '';
  }

  validate(data: SpecialLocale[], locale: string = null): boolean {
    if (!locale) {
      return data.length > 0;
    }
    let textData = data.find(row => row.locale === locale).data as string;
    return textData.length > 0;
  }

  generateDefaultDataObject(locale: string): SpecialLocale[] {
    return [
      {
        locale: locale,
        data: '',
      },
    ] as SpecialLocale[];
  }
}

export class GeoCoordsDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: GeoCoords, locale?: string): Promise<string> {
    return `${data.latitude}, ${data.longitude}`;
  }

  validate(data: GeoCoords): boolean {
    return data.latitude > 0 && data.longitude > 0;
  }

  generateDefaultDataObject(): GeoCoords {
    return { longitude: 0, latitude: 0 } as GeoCoords;
  }
}

export class AutoIncrementDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: AutoIncrementData): Promise<string> {
    return `${data.number}`;
  }

  validate(data: AutoIncrementData): boolean {
    return data.number > 0;
  }

  generateDefaultDataObject(): AutoIncrementData {
    return { number: 0, new: false } as AutoIncrementData;
  }
}

export class DropdownDataTypeClass extends BaseDataTypeClass {
  constructor(
    private collectionItemStore: Store<CollectionItemsState>,
    private localeUtilService: LocaleUtilService) {
    super();
  }

  async getDataAsString(data: DropdownData): Promise<string> {
    if(!data.collectionItemId) {
      return "";
    }

    let item = await firstValueFrom(
      this.collectionItemStore.pipe(
        select(getItemById(data.collectionItemId)),
        filter(item => !!item),
      ),
    );

    //todo: Validate if this works. Shouldnt the await block the dispatch?

    this.collectionItemStore.dispatch(
      CollectionItemActions.loadCollectionItem({ collectionItemId: data.collectionItemId }),
    );

    console.log('Item', item);
    let name = this.localeUtilService.getFromLocales(item.name).data;
    return name || data.collectionItemId
  }

  validate(data: DropdownData): boolean {
    return data.collectionItemId && data.collectionItemId.length > 0;
  }

  generateDefaultDataObject(): DropdownData {
    return { collectionItemId: null } as DropdownData;
  }
}

export class DateTimeDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: DateTimeData): Promise<string> {
    return data.utc;
  }

  validate(data: DateTimeData): boolean {
    return data.utc.length > 0;
  }

  generateDefaultDataObject(): DateTimeData {
    return { utc: new Date().toUTCString() } as DateTimeData;
  }
}

export class DateDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: DateData): Promise<string> {
    return `${data.day}-${data.month}-${data.year}`;
  }

  validate(data: DateData): boolean {
    return data !== null;
  }

  generateDefaultDataObject(): DateData {
    return { day: null, month: null, year: null} as DateData;
  }
}

export class ReminderDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: ReminderData): Promise<string> {
    return data.receivers.join(', ');
  }

  validate(data: ReminderData): boolean {
    return data.dateTime.utc.length > 0 && data.receivers.length > 0;
  }

  generateDefaultDataObject(): ReminderData {
    return { dateTime: { utc: new Date().toUTCString() }, receivers: [] } as ReminderData;
  }
}

export class ProcedureDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: ProcedureData): Promise<string> {
    return data.procedureId;
  }

  validate(data: ProcedureData): boolean {
    return data.procedureId && data.procedureId.length > 0;
  }

  generateDefaultDataObject(): ProcedureData {
    return { procedureId: null } as ProcedureData;
  }
}

export class DimensionsDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: DimensionsData): Promise<string> {
    return `${data.length} x ${data.width} x ${data.height} ${data.unit}`;
  }

  validate(data: DimensionsData): boolean {
    return true;
  }

  generateDefaultDataObject(): DimensionsData {
    return { unit: 'cm', width: null, length: null, height: null } as DimensionsData;
  }
}

export class NumberDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: NumberData): Promise<string> {
    return data.value;
  }

  validate(data: NumberData): boolean {
    return !!data.value;
  }

  generateDefaultDataObject(): NumberData {
    return { value: null } as NumberData;
  }
}

export class FileDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: FileData): Promise<string> {
    return data.name || data.fileId;
  }

  validate(data: FileData): boolean {
    return !!data.fileId;
  }

  generateDefaultDataObject(): FileData {
    return { fileId: null } as FileData;
  }
}

export class OptionDataTypeClass extends BaseDataTypeClass {
  async getDataAsString(data: OptionData): Promise<string> {
    return data.key;
  }

  validate(data: OptionData): boolean {
    console.log('OptionDataTypeClass.validate', data);
    return !!data.key;
  }

  generateDefaultDataObject(): OptionData {
    return { key: null } as OptionData;
  }
}

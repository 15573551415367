import { Component, inject, Inject } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { ThemePalette } from '@angular/material/core';
import { Observable, takeUntil } from 'rxjs';
import { BaseComponent } from '@dash/randy/shared/classes/baseComponent';

export interface NotifyLoadingModel { busy: boolean; message?: string }

@Component({
  selector: 'app-custom-snackbar',
  template: `
    <div class="custom-snackbar primary" *ngIf="status$ | async as status">
      <div class="img-wrapper"><img src="https://kleksi.com/img/kleksi_logo_anim.png" /></div>
      <span>{{ status.message }}</span>
    </div>
  `,
  styles: [
    `
      .custom-snackbar {
        font-size: 1.1rem;
        font-weight: 400;
        color: #292C32;
        padding: 14px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;

        &.primary {
          background-color: var(--accent-color);
        }

        .img-wrapper {
          width: 40px;
          height: 40px;

          img {
            height: 100%;
            filter: invert(1);
          }
        }
      }
    `,
  ],
})
export class LoadingSnackbarComponent extends BaseComponent {
  status$: Observable<NotifyLoadingModel>;

  constructor(
    private _snackBar: MatSnackBar,
    public snackBarRef: MatSnackBarRef<LoadingSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      status: Observable<{ busy: boolean; message: string }>;
      duration?: number;
    },
  ) {
    super();
    this.status$ = data.status;

    this.status$.pipe(takeUntil(this.destroyed$)).subscribe(status => {
      if (!status.busy) {
        setTimeout(() => {
          this.snackBarRef.dismiss();
        }, data?.duration || 2000);
      }
    });
  }
}

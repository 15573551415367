import { Directive, Input, OnDestroy, AfterViewInit, Host, Optional, Self, OnInit } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'mat-paginator[persistPaginator]',
})
export class PersistPaginatorDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input('persistPaginator') storageKey!: string;
  private subscription!: Subscription;

  constructor(@Host() @Self() @Optional() private readonly matPag: MatPaginator) {}

  ngOnInit() {
    console.log('this.matPag', this.matPag);
    const storedPageSize = localStorage.getItem(this.storageKey);

    setTimeout(() => {
      if (storedPageSize) {
        this.matPag.pageSize = +storedPageSize;
      }else{
        this.matPag.pageSize = 25;
      }
    }, 0)
  }

  ngAfterViewInit() {
    this.subscription = this.matPag.page.subscribe((event: PageEvent) => {
      localStorage.setItem(this.storageKey, event.pageSize.toString());
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
